<template>
    <div class="d-flex flex-nowrap align-center justify-center">
        <v-text-field
            v-model="display"
            :label="field.titleSingular"
            readonly
            :rules="getRules()"
            :dense="isDense"
            @click="edit" />

        <v-dialog :key="refreshKey" v-model="dialog" max-width="1000px">
            <v-form ref="editForm" @submit="onSave" @submit.prevent>
                <v-card>
                    <v-card-title>
                        <span class="headline">Choose Coordinate</span>
                    </v-card-title>

                    <v-card-text>
                        <v-text-field v-model="latitude" label="Latitude" @change="moveMarker()" />
                        <v-text-field 
                            v-model="longitude" 
                            label="Longitude" 
                            @change="moveMarker()" />
                        <div style="overflow:hidden;z-index:-999;">
                            <div ref="map" class="map-size" />
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            color="quinary"
                            text
                            :disabled="isReadonly"
                            @click="onDelete">
                            Clear
                        </v-btn>
                        <div class="flex-grow-1" />
                        <v-btn color="quaternary" text @click="closeDialog">
                            Cancel
                        </v-btn>
                        <v-btn
                            type="submit"
                            color="quaternary"
                            text
                            :disabled="isReadonly">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>

export default {
    props: {
        value: {
            type: Object,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
        isDense: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            coordinates: null,
            map: null,
            infoWindow: null,
            loading: false,
            dialog: false,
            editedItem: null,
            display: null,
            isClicked: false,
            refreshKey: 0,
            latitude: -31.95,
            longitude: 115.86,
            browserLocation: null
        };
    },

    computed: {
        google() {
            return this.$store.state.google.google;
        }
    },

    watch: {
        value() {
            this.setDisplay(this.value);
        },
    },

    mounted() {
        this.browserLocation = new this.google.maps.LatLng(-31.95, 115.86);
        this.setDisplay(this.value);
    },

    methods: {
        load() {
            this.coordinates = this.value;
            if (navigator.geolocation) {
                let self = this;
                navigator.geolocation.getCurrentPosition(position => {
                    self.latitude = position.coords.latitude;
                    self.longitude = position.coords.longitude;
                    self.browserLocation = new this.google.maps.LatLng(
                        self.latitude, 
                        self.longitude);
                    self.setMapPosition();
                }, positionError => {
                    positionError;
                    self.setMapPosition();
                });
            }

        },
        setMapPosition() {
            let startCoordinates = null;
            if (this.coordinates) {
                let latitude = this.coordinates.latitude;
                let longitude = this.coordinates.longitude;
                let defaultLatitude = this.round(latitude, 4);
                let defaultLongitude = this.round(longitude, 4);
                startCoordinates = new this.google.maps.LatLng(defaultLatitude, defaultLongitude);
            }
            else {
                startCoordinates = this.browserLocation;
            }

            let options = {
                zoom: 10,
                center: startCoordinates,
                gestureHandling: 'greedy'
            };

            let div = this.$refs.map;
            this.map = new this.google.maps.Map(div, options);

            let content = "";
            if (!this.coordinates) {
                content = 'Click the map to choose a coordinate';
            }
            else {
                let latitude = this.coordinates.latitude;
                let longitude = this.coordinates.longitude;
                content = this.displayCoordinates(latitude, longitude);
            }
            this.infoWindow = new this.google.maps.InfoWindow(
                { content: content, position: startCoordinates });
            this.infoWindow.open(this.map);

            let self = this;
            // Configure the click listener.
            this.map.addListener('click', (mapsMouseEvent) => self.openInfoWindow(mapsMouseEvent));
        },
        moveMarker() {
            let latLng = new this.google.maps.LatLng(this.latitude, this.longitude);
            this.map.setCenter(latLng);

            this.infoWindow.close();

            this.coordinates = { latitude: this.latitude, longitude: this.longitude };

            // Create a new InfoWindow.
            this.infoWindow = new this.google.maps.InfoWindow({ position: latLng });
            this.infoWindow.setContent(this.latitude + ", " + this.longitude);
            this.infoWindow.open(this.map);
        },
        openInfoWindow(mapsMouseEvent) {
            // Close the current InfoWindow.
            this.infoWindow.close();

            this.latitude = mapsMouseEvent.latLng.lat();
            this.longitude = mapsMouseEvent.latLng.lng();
            this.coordinates = { latitude: this.latitude, longitude: this.longitude };

            // Create a new InfoWindow.
            this.infoWindow = new this.google.maps.InfoWindow({ position: mapsMouseEvent.latLng });
            this.infoWindow.setContent(this.displayCoordinates(this.latitude, this.longitude));
            this.infoWindow.open(this.map);
        },
        round(number, decimalPlaces) {
            return Math.round((number + Number.EPSILON) * Math.pow(10, decimalPlaces)) 
                / Math.pow(10, decimalPlaces);
        },
        edit() {
            if (this.isReadonly) { return; }
            this.isClicked = true;
            this.refreshKey++;
            this.dialog = true;
            setTimeout(this.load, 10);
        },
        onSave() {
            this.setDisplay(this.coordinates);
            this.$emit('input', this.coordinates);
            this.closeDialog();
        },
        closeDialog() {
            this.dialog = false;
        },
        getRules() {
            if (this.isClicked) {
                return this.field.rules;
            }
            return [];
        },
        onDelete() {
            this.display = "";
            this.$emit('input', null);
            this.closeDialog();
        },
        displayCoordinates(lat, lng) {
            return this.round(lat, 4) + ", " + this.round(lng, 4);
        },
        setDisplay(value) {
            if (this.isEmpty(value)) {
                this.display = "";
                return;
            }
            let coordinateDisplay = this.displayCoordinates(value.latitude, value.longitude);
            this.display = value ? "(" + coordinateDisplay + ")" : "";
        }
    }
};
</script>

<style scoped>
    .map-size {
        width: 100%;
        height: 50vh;
    }
</style>
